<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="credit" backlink="/credit/members" />
      <div class="content">
        <div class="content__container">
          <Question
            name="have_credit"
            service="credit"
            question="question_9"
            successUrl="/credit/ref-credit"
            negativeUrl="/credit/reg-payment"
            :droppedSteps="['/credit/ref-credit', '/credit/amount-credits', '/credit/about-credits']"
            :negativeMissedSteps="['/credit/ref-credit', '/credit/amount-credits', '/credit/about-credits']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'

export default {
  name: 'Credit_have_credit',
  components: { Backlink, Question }
}
</script>
